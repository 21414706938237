var viewportWidth = $(window).width();
	/*if (viewportWidth < 768) {
		$("#collapseCategories").removeClass("in");
		$('.categories-dropdown').attr('aria-expanded', 'false');
	}else {
		$("#collapseCategories").addClass("in");
		$('.categories-dropdown').attr('aria-expanded', 'true');
	}*/


function stickyFooter () {
  var footerHeight = $('footer').outerHeight();
  $('body').css('margin-bottom', footerHeight);
}

$(document).ready(function() {
	var viewportWidth = $(window).width();
	var startViewportWidth = $(window).width();

	var viewportWidth = $(window).width();
	/*if (viewportWidth < 768) {
		$("#collapseCategories").removeClass("in");
		$('.categories-dropdown').attr('aria-expanded', 'false');
	}else {
		$("#collapseCategories").addClass("in");
		$('.categories-dropdown').attr('aria-expanded', 'true');
	}*/

	$( ".next-prev-post .prev-wrap h3 a" ).mouseenter(function() {
	  $('.prev-wrap .prev-article-img').addClass('img-zoom');
	});

	$( ".next-prev-post .prev-wrap h3 a" ).mouseleave(function() {
	  $('.prev-wrap .prev-article-img').removeClass('img-zoom');
	});

	$( ".next-prev-post .next-wrap h3 a" ).mouseenter(function() {
	  $('.next-wrap .next-article-img').addClass('img-zoom');
	});

	$( ".next-prev-post .next-wrap h3 a" ).mouseleave(function() {
	  $('.next-wrap .next-article-img').removeClass('img-zoom');
	});

	//ADD THIS SOCIAL
  $('.art-widget a').on('click', function() {
    ga('send', 'event', 'Add This', $(this).attr('class'), $('.art-widget a').parent().parent().find('.single-article-container article.page-content .entry-header .article-title').text());
  });


	$('.page-content .article-title').each(function() {
	    var text = $(this).text()
	      .replace('SRT®', 'SRT<sup class="realign superscript">®</sup>')
	      .replace('Mopar®', 'Mopar<sub class="realign subscript">®</sub>');

	    $(this).html(text);
	});

	$('.show-pending').on('click', function () {
		$('.pending-comment').slideToggle();
		$(this).text($(this).text() === 'Hide Pending' ? 'Show Pending' : 'Hide Pending');
	});

  //stickyFooter();

	$(window).resize(function() {
	  	viewportWidth = $(window).width();
	  	if(viewportWidth != startViewportWidth){
	  		if (viewportWidth < 768) {
	      		$("#collapseCategories").removeClass("in");
	      		//$('.categories-dropdown').attr('aria-expanded', 'false');
  			}else {
  				$("#collapseCategories").addClass("in");
  				//$('.categories-dropdown').attr('aria-expanded', 'true');
  			}
	  	}
    //stickyFooter();
	});

});
$(window).load(function() {
  //stickyFooter();
});

function time() {
	var timestamp = Math.floor(new Date().getTime() / 1000)
	return timestamp;
}
