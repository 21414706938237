document.addEventListener('DOMContentLoaded', function(event) {
  // add includes polyfill for IE
  if (!String.prototype.includes) {
    String.prototype.includes = function(search, start) {
      'use strict';

      if (search instanceof RegExp) {
        throw TypeError('first argument must not be a RegExp');
      }
      if (start === undefined) { start = 0; }
      return this.indexOf(search, start) !== -1;
    };
  }
  // Listen for document clicks
  document.addEventListener("click", function (e) {
    var link = e.target.closest('a');
    if (link !== null && !link.classList.contains('ga-event')) { // Check if there's a link ancestor and does not have ga-events class
      const domains = Array('www.dodgegarage.com', 'dodgegarage.com', 'localhost:4200', 'localhost:4000', 'test.dodgegarage.com');
      var host = link.host;
      if (!domains.includes(host) && host.length > 0) { // Check if link goes to an external site
         if (link.href.includes('mailto:')) {
           ga('send', 'event', 'Mailto', link.href, document.location.href);
         } else {
           ga('send', 'event', 'Outbound', link.href, document.location.href);
         }
      }
    } else if (link !== null && link.classList.contains('ga-event')) { // Check if the link has the ga-events class for custom tracking
      // Look for the proper data tags on the element
      // Get the GA category
      var ga_category = link.getAttribute('data-ga_category') ? link.getAttribute('data-ga_category') : 'Click';
      // Get the GA action, adds the county
      var ga_action = (link.getAttribute('data-ga_action') ? link.getAttribute('data-ga_action') : 'Click');
      // Get the GA Label, if empty, then simply return the link
      if (link.getAttribute('data-ga_label')) {
        var ga_label = link.getAttribute('data-ga_label');
      } else {
        var ga_label = link.href;
      }

      ga('send', 'event', ga_category, ga_action, ga_label);
      console.log(ga_category, ga_action, ga_label)
    }
  })
});

/*
Sample Code:
<a href="/resources/pdf/moparone/promos/mmobm-tie-rods-annoucement.pdf" class="btn btn-primary-outline ga-ff-et ga-event" target="_blank" data-ga_category="FF_Click" data-ga_action="Wholesale_CTA">LEARN MORE</a>

Result:
ga('send', 'event', 'FF_Click', 'US_Wholesale_CTA', '/resources/pdf/moparone/promos/mmobm-tie-rods-annoucement.pdf');
 */
