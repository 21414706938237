$(document).ready(function() {
  var message = $('#message').val(),
      timestamp = $('#timestamp').val(),
      signature = $('#signature').val();

  //----------------------------
  //Event Listeners
  //----------------------------
  //If MUUT Login or SSO Link is click show the signin form
  $(document).on('click', 'a.m-login, a.m-sso-link,.m-no-logged .m-postinput', function(e) {
    hideShowModal('.signin-form');
  });

  $(document).on('click','.create-account a, .modal-member-login a, .back-btn, #sign-up-to-share-lnk', function(e){
    e.preventDefault();
    hideShowModal('.'+ $(this).data('form'));
  });

  $(document).on('click','.m-logged .m-postinput', function(e) {
    $('.m-controls button').show();
  });

  $(document).on('click','#resend-activation-message a',function(e){
    e.preventDefault();
    $('#resend-activation-form.ajax-form').submit();
  });

  //Username Availability
  $(document).on('keyup','#create-username', function(e){
    if($(this).val()== ''){
      $('#username-available').hide();
      $('#username-unavailable').hide();
    }
    else {
      var formData = [{name:'username',value:$(this).val()}];

      //Make AJAX call
      /*ajaxCall('/rest/api/authentication/username-lookup.php', 'POST', formData,'username', function(data){
        if(data == 'success'){
          $('#username-unavailable').hide();
          $('#username-available').show();
        }
        else{
          $('#username-available').hide();
          $('#username-unavailable').show();
        }
      });*/
    }
  });

  //ajax form submit
  $(document).on('submit','.ajax-form',function(e){
    e.preventDefault();
    var formData = $(this).serializeArray().reduce(function(obj, item) {obj[item.name] = item.value;return obj;}, {}),
      formName = $(this).data('name'),
      formAlert = $('#'+ formName +'-alert');

    var testUrl = '/rest/';
    if ( formName == 'login' ){
      testUrl = testUrl + 'api/' + formName;
    }
    else{
      testUrl = testUrl + 'user/' + formName + '/';
    }

    //Make AJAX call
    ajaxCall(testUrl, 'POST', formData, formName, function(data){
      //Test for success from ajax call
      if(data.refresh_token){
        switch (formName) {
          case 'register':
          case 'login':
          case 'reset-password':
              loggedIn(formName ,formData.username);
              break;
          case 'change-password':
              $('.password-details').val('');
              break;
          case 'activation':
              setTimeout(function() {$('#signinModal').modal('hide');$('.activation-form').hide();$('.create-form').show();}, 5000);
              break;
        }
        alertMessage(formAlert,data.message,'alert-success');
      }
      else{
        alertMessage(formAlert,data.message,'alert-danger');
      }
    });
  });

  //----------------------------
  // URL Parameters
  //----------------------------
  //Check parameter for Login
  if(getParameterByName('login')){
    resetURL();
    hideShowModal('.signin-form');
    $('#signinModal').modal('show');
  }

  //Check parameter for reset token
  /*if(getParameterByName('resetToken')){
    $('#resetToken').val(getParameterByName('resetToken'));
    $('#reset-password-email').val(getParameterByName('email'));
    //resetURL();
    hideShowModal('.reset-password-form');
  }*/
}); //End document.ready



//----------------------------
// Helper Functions
//----------------------------
function alertMessage(element,message,type){
  element.html(message).removeClass('alert-danger alert-success hide').addClass(type).show().delay(2500).fadeOut();
  setTimeout(function() {
    element.addClass('hide').fadeIn();
  }, 3000);
}

//Hide all modal forms and show the passed in form
function hideShowModal(show){
  $('.modal-form').hide();
  $(show).show();

  $('#signinModal').modal('show');
}

function hideModal(){
  $('#signinModal').modal('hide');
}


//Reset the state of the URL
function resetURL(){
  history.pushState(null, null, '/news');
}

//Make an ajax call where callback is the function of the done method
function ajaxCall(url, method, formData, formName, callback){
  //Add CSRF token
  $.ajax({
    url: url,
    type: method,
    contentType: 'application/json',
    cache: false,
    data: JSON.stringify(formData)
  }).done(callback).fail(function(data){
     alertMessage($('#'+ formName +'-alert'),'An error has occurred.','alert-danger');
  });
}

function loggedIn(area,user){

  setTimeout(function() {$('#signinModal').modal('hide');}, 5000);

  //initialize muut sso login
  if(!user.muut){
    $('.m-sso-link').hide();
    $('.m-login').hide();
    $('#not-verfied-warning').show();
  }
}

/*function muutless(articleId){  // Out of Date 2018/08/13
  $('.muuts').replaceWith('<a class="muuts" data-show_title="false" >Comments</a>');

  setTimeout(function () {
    $('body').find('.muuts').muut({
      url: 'https://muut.com/i/dodgegarage/comments:'+articleId
    });
  }, 2000);
}

function initMuutLogin(articleId, message, signature, timestamp, user){ // Out of Date 2018/08/13
  //$('.muuts').replaceWith('<a class="muuts" data-show_title="false" >Comments</a>');
  setTimeout(function () {
    $('body').find('.muuts').muut({
      url: 'https://muut.com/i/dodgegarage/comments:'+articleId,
      login_url: "#",
      sso: {
        key: 'AkO5KSDmdT',
        message: message,
        signature: signature,
        timestamp: timestamp
      }
    }, function () {
      // If User has Racing Pledge, add code
      if (user.noRacingPledge) {
        setTimeout(function () {

          // Check Current User Status
          var userEmail = $('body').find('.m-myname').text();
          if (userEmail = user.email) {
            var currentUserDiv = $('body').find('.m-me');
            currentUserDiv.find('.m-face').wrap("<span class='no-racing-pledge'></span>");
          }
          // Check Each Reply
          $('body').find('.m-reply').each(function () {
            if ($(this).find('.m-facelink').attr('title') == user.email) {
              $(this).find('.m-face').wrap("<span class='no-racing-pledge'></span>");
            }
          });
        }, 2000);
      }
    });
  }, 2000);



}*/



function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function validator(formData,area){
  var validation = true;
  var nameReg = /^[A-Za-z]+$/;
  var numberReg =  /^[0-9]+$/;
  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  var alphaNumReg = /^[A-Za-z0-9 _.-]+$/;
  var errorList = '';

  $(formData).each(function(i, field){
    var name = formData[i].name;
    var value = formData[i].value;
    switch (name) {
        case 'first_name':
          if(value == ''){
            errorList += '<li>First Name is blank.</li>';
            validation = false;
          }else if (!nameReg.test(value)){
            errorList += '<li>First name can only be alpha characters.</li>';
            validation = false;
          }
          break;
        case 'last_name':
          if(value == ''){
            errorList += '<li>Last name is blank.</li>';
            validation = false;
          }else if (!nameReg.test(value)){
            errorList += '<li>Last name can only be alpha characters.</li>';
            validation = false;
          }
          break;
        case 'username':
          if(value == ''){
            errorList += '<li>Username is blank.</li>';
            validation = false;
          }else if (!alphaNumReg.test(value)){
            errorList += '<li>Username can only be AlphaNumeric characters.</li>';
            validation = false;
          }
          break;
        case 'email':
          if(value == ''){
            errorList += '<li>Email is blank.</li>';
            validation = false;
          }else if (!emailReg.test(value)){
            errorList += '<li>Email is not a valid email address.</li>';
            validation = false;
          }
          break;
        case 'emailUsername':
          if(value == ''){
            errorList += '<li>Email/Username is blank.</li>';
            validation = false;
          }
          break;
        case 'password':
          if(value == ''){
            errorList += '<li>Password is blank.</li>';
            validation = false;
          }else if (!alphaNumReg.test(value)){
            errorList += '<li>Password can only be alphanumeric characters.</li>';
            validation = false;
          }
          break;
        case 'confirmPassword':
          if(value == ''){
            errorList += '<li>Confirm Password is blank.</li>';
            validation = false;
          }else if (!alphaNumReg.test(value)){
            errorList += '<li>Confirm Password can only be alphanumeric characters.</li>';
            validation = false;
          }else if ($('#create-password').val() != $('#confirm-create-password').val()){
            errorList += '<li>Passwords must match.</li>';
            validation = false;
          }
          break;
        case 'newPassword':
          if(value == ''){
            errorList += '<li>Password is blank.</li>';
            validation = false;
          }else if (!alphaNumReg.test(value)){
            errorList += '<li>Password can only be alphanumeric characters.</li>';
            validation = false;
          }
          break;
        case 'confirmNewPassword':
          if(value == ''){
            errorList += '<li>Confirm Password is blank.</li>';
            validation = false;
          }else if (!alphaNumReg.test(value)){
            errorList += '<li>Confirm Password can only be alphanumeric characters.</li>';
            validation = false;
          }else if ($('#new-password').val() != $('#confirm-new-password').val()){
            errorList += '<li>Passwords must match.</li>';
            validation = false;
          }
          break;
        case 'resetToken','activationToken':

          break;

    }
  });
  if (!validation){
    alertMessage($('#'+area+'-alert'),errorList,'alert-danger');
  }
  return validation;
}
